import React from 'react';
import './random.css'
import Food from './food';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react'
const Random = () => {
    const foods = [
        { id: 1, name: '火锅', img: "https://www.gro-tech.nl/wp-content/uploads/2023/05/hotpot.jpg", link: "https://www.yelp.com/search?find_desc=Chinese+hotpot&find_loc=Flushing%2C+Queens%2C+NY" },
        { id: 2, name: '中餐', img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM-WXVMrbGtMmtrTg1ytjFcsk_CoqSenceqg&usqp=CAU", link: "https://www.yelp.com/search?find_desc=Chinese+Restaurant&find_loc=Flushing%2C+Queens%2C+NY" },
        { id: 3, name: '韩餐', img: "https://p2.itc.cn/q_70/images03/20221013/6859f41df0164a83ae9e2f20f6356e20.png", link: "https://www.yelp.com/search?find_desc=Korean+Food&find_loc=Flushing%2C+NY" },
        { id: 4, name: '泰餐', img: "https://we.superlife.ca/files/4ffce04d92a4d6cb21c1494cdfcd6dc129153.jpg", link: "https://www.yelp.com/search?find_desc=Thai+Food&find_loc=Flushing%2C+NY" },
        { id: 5, name: '烤肉', img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNpwZA4qXHunkOZmF9kB_fiGJypZ0g_nO_sw&usqp=CAU", link: "https://www.yelp.com/search?find_desc=bbq&find_loc=Flushing%2C+NY" },
        { id: 6, name: '烤鱼', img: "https://media-cdn.tripadvisor.com/media/photo-s/19/2a/d1/29/best-seller-chongking.jpg", link: "https://www.yelp.com/search?find_desc=%E7%83%A4%E9%B1%BC&find_loc=Flushing%2C+NY" },
    ];
    const [randomFood, setRandomFood] = useState("Random Food waiting... ");
    const [food, setFood] = useState("...");
    const [clickTime, setClickTime] = useState(0);
    const handleClick = () => {
        const min = 0;
        const max = 5;
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        // console.log("button click" + "the random number is "+ randomNum);
        setRandomFood(foods[randomNum].name);
        setFood(foods[randomNum])
        setClickTime(clickTime + 1);
    }

    return (
        <div className='main'>

            <Food />
            <div className="randomChoose m-5 text-center">
                <h2>Click the button to random choose</h2>
                <button className='btn btn-primary btn-lg w-25' onClick={handleClick}>随便</button>
                <h2 className='p-5'>随机食物是:  
                    <Link className='selectFood  px-3' to={food.link}>{food.name}</Link> 
                </h2>
                    <small className='text-end text-danger'>Click it to see the yelp top 10 of your select food!</small>
                <h1>You have click :{clickTime}</h1>
            </div>
        </div>



    );
}

export default Random;
