import React from 'react'
import { useAuth } from './AuthContext';
import  {useNavigate} from "react-router-dom";
export default function Logout() {
    const { isLoggedIn, login, logout } = useAuth();
    const navigate = useNavigate(); 
  return (
    <p>You have successfully logout!
        
    </p>
  )
}
