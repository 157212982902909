import React from 'react'
import {Link} from 'react-router-dom'
export default function NotFound() {
  return (
   <div className="notfound">
        <h2>Page Not Found!</h2>
        <h3>Please check you Url Link </h3>

        <Link to={'/'}>Back to the Home...</Link>
   </div>
  )
}
