import React from 'react';
import './foot.css'
const Foot = () => {
    return (
        <div className='foot'>
            &copy; 2024 Xiaoqian He. All Rights Reserved.
        </div>
    );
}

export default Foot;
