// FoodMenu.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './foodmenu.css'
import YelpHotpotList from './YelpHotpotList';
const FoodMenu = ({ foodCategories }) => {
  const { categoryId } = useParams();

  // Find the selected category based on the ID from the URL params
  const selectedCategory = foodCategories.find(category => category.id === parseInt(categoryId));

  const hotpots = [{id:1, name:"海底捞", yelpUrl:"https://www.yelp.com/biz/haidilao-hot-pot-flushing-flushing?osq=Hot+Pot", imgUrl:"https://s3-media0.fl.yelpcdn.com/bphoto/WiGBUxZsBHYIMiUMQTTH7Q/o.jpg",time:"unitl 10:00PM", rate:4.3}, 


  ]


  if (!selectedCategory) {
    // Handle the case where no category is selected (e.g., show a message)
    return <p>No category selected</p>;
  }

  return (
    <div className='main-container'>
      <h3>Details for {selectedCategory.name}</h3>
      <p>ID: {selectedCategory.id}</p>
      <img src={selectedCategory.img} alt="" />
      {/* You can display other details of the category as needed */}
      <YelpHotpotList />
    </div>
  );
};

export default FoodMenu;
