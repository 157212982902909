import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../Login/AuthContext';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBBtn
} from 'mdb-react-ui-kit';
import './comments.css'
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import emailjs from '@emailjs/browser';
const firebaseConfig = {
  apiKey: "AIzaSyDeS5UADexLO21glPIoj5dD8mFLmM4F8zc",
  authDomain: "blog-database-3.firebaseapp.com",
  databaseURL: "https://blog-database-3-default-rtdb.firebaseio.com",
  projectId: "blog-database-3",
  storageBucket: "blog-database-3.appspot.com",
  messagingSenderId: "138526460738",
  appId: "1:138526460738:web:dd824d10f09bb1df5a96db",
  measurementId: "G-7QZ0DH624S"
};
const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const Comments = ({ blogId }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addBtn, setAddBtn] = useState(false);
  const [name, setName] = useState('');
  const [email, setemail] = useState('');
  const [commentId, setCommentId] = useState();
  const [content, setContent] = useState('');
  const currentDate = new Date().toDateString();
  const [firebaseId, setFirebaseId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const { isLoggedIn, login, logout } = useAuth();

    // State to manage the checkbox value
    const [isChecked, setIsChecked] = useState(false);

    // Function to handle checkbox change
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked); // Toggle the isChecked state
    };
  
  const databaseURL = "https://blog-database-3-default-rtdb.firebaseio.com";
    
  // const databaseURL = storage.firebaseConfig.databaseURL;
  const fetchComments = async () => {
    try {
      const response = await fetch(`${databaseURL}/comments.json`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Filter comments based on the current blogId
      const filteredComments = Object.values(data).filter(comment => comment.blogId === blogId);

      setComments(filteredComments);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchComments();
  }, [blogId]);

  if (loading) {
    return <p>Loading comments...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  const handleAddBtn = () => {
    setAddBtn(true);
  }
  const handleCancel = (e) => {
    if (e) {
      e.preventDefault();
    }
    setAddBtn(false);

  }
  const sendCopy = (comment) => {
    const serviceId = 'service_6h8efxu';
    const templateId = 'template_q0nl47d';
    const API_KEY = 'du0jdXf_WHqQDu7AU'
    const templateParams = {
      to_name: name,
      from_name: 'Shawn He',
      message: content
    };
    emailjs.send(serviceId, templateId, templateParams,API_KEY)
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setShowAlert(true); 
    }, (error) => {
      console.log('FAILED...', error);
    });

  };
  const handleSubmit = async (e) => {
      e.preventDefault();

    try {
      const data = await fetch(`${databaseURL}/comments.json`);
      if (!data.ok) {
        throw new Error(`HTTP error! Status: ${data.status}`);
      }

      setCommentId(comments.length);
      const newComment = { blogId, name, email, content, date: currentDate, id: comments.length };
      console.log(commentId)
      // Use the result variable to avoid reading the body again
      const result = await fetch(`${databaseURL}/comments.json`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newComment),
      });

      if (!result.ok) {
        throw new Error(`HTTP error! Status: ${result.status}`);
      }

      // console.log('New comment created:', result);
      const responseData = await result.json();
      const firebaseId = responseData.name; // Get the first key from the response

      // console.log('New comment created with Firebase ID:', firebaseId);
      fetchComments();
      handleCancel();
      if(isChecked) {
        sendCopy(content); 
      }

      //this one says tje fetchments is not defined
      // window.location.reload();
      // Consider updating the state or taking appropriate action instead of reloading the entire page
    } catch (error) {
      console.error('Error adding comment:', error);
      // Handle error appropriately
    }
  }
  return (
    <div>

      <div className="fixed-center d-flex justify-content-between align-items-center">
        <h2 className="col-6">Comments:</h2>
        <div className="col-6 w-25 mx-5 fix-center">
        {!addBtn && <button className=" btn btn-success" onClick={handleAddBtn}>Add Comment</button>}
        </div>
      </div>

      {comments.length === 0 ? (
        <p>No comments yet. Be the first to comment!</p>
      ) : (
        <ul>
          {comments.map(comment => (
            <div key={comment.Id}>
              <h4 className="name">Name: <small> {comment.name}</small></h4>
              <p className="date">Date: {comment.date}</p>
              <p>content: {comment.content}</p>

              <hr />
            </div>
          ))}
        </ul>
      )}
      {/* <hr></hr> */}


      {
        addBtn &&
        <div className="overlay">
        <form className='commentForm' onSubmit={handleSubmit}>
          <h3 className='text-center p-2'>Comment</h3>
          <MDBInput id='name' wrapperClass='mb-4' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' required />
          <MDBInput type='email' wrapperClass='mb-4' value={email} onChange={(e) => setemail(e.target.value)} placeholder='Email address' />
          <MDBInput className='message' wrapperClass='mb-4' textarea='true' rows={7} value={content} onChange={(e) => setContent(e.target.value)} placeholder='Message' required />

          <MDBCheckbox
            wrapperClass='d-flex justify-content-center mb-4'
            label='Send me a copy of this message'
            checked={isChecked}
            onChange={handleCheckboxChange}
            // defaultChecked
            
          />
          <div className="commentbtn">

            {/* <MDBBtn type='submit' className='mb-4 btn-success mx-2' block>
              Submit
            </MDBBtn> */}
            <MDBBtn type='canel' className='cancel btn-secondary mb-4 mx-2' onClick={handleCancel} block>
              Cancel
            </MDBBtn>
            <MDBBtn type='submit' className='mb-4 btn-success mx-2' block>
              Submit
            </MDBBtn>
          </div>
        </form>
        {showAlert && (
        <div className="custom-alert">
          <div className="alert-content">
            <span className="close" onClick={() => setShowAlert(false)}>&times;</span>
            <p>Email sent successfully!</p>
          </div>
        </div>
      )}

        </div>
      }

    </div>
  );
};

export default Comments;
