import React from 'react'
import { useState } from 'react'
import BlogDetails from './BlogDetails';
import { useNavigate } from 'react-router-dom';
import UploadImage from './uploadImage';

export default function () {
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [author, setAuthor] = useState('');
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate(); 
  const currentDate = new Date().toDateString(); 
   const url = `https://blog-database-3-default-rtdb.firebaseio.com/blogs.json`;

   const handleImageUpload = (imageUrl) => {
    setImage(imageUrl);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); //this is prevent page will not be refresh, so the data is not lost, even you click the button
    
    //This is for the Id generate 
    const res = await fetch(url);
    const existingData = await res.json(); 
    const newId = existingData.length; 
    // console.log("new id is :"+newId); 
    //*********************************** */
   
    const blog = { image, title, body, author,date:currentDate, id:newId};
    setIsPending(true); 

    fetch(url, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },  // Fix the typo here
      body: JSON.stringify(blog)
    }).then(() => {
      console.log("new blog created!")
      setIsPending(false);
      //-1 means I want go back one step before you just open
     navigate('/blogs'); 
      //redirectly to the path we put in parenthese
    })


  }

  return (
    <div className="create">
      <h2>Create New Blogs</h2>
      <form onSubmit={handleSubmit}>
      <UploadImage onImageUpload={handleImageUpload} />
        <label >Image Link: </label>
        <input type="text" value={image} onChange={(e) => setImage(e.target.value)} readOnly />
        <label>Blog title: </label>
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        <label>Blog Body: </label>
        <textarea className='textBody' value={body} onChange={(e) => setBody(e.target.value)}  required></textarea>
        <label>Blog author:</label>
        <select value={author} onChange={(e) => setAuthor(e.target.value)}>
          <option></option>
          <option value={"shawn"}>Shawn</option>
          <option value={"xiaoqian"}>Xiaoqian</option>
        </select>
       {!isPending && <button type='submit'> Add Blog</button>}
       {isPending && <button disabled> Adding new blog ...</button>}
      </form>

    </div>

  )
}
