import React from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
export default function About() {
  return (
    <section class="about section" id="about">
      <Sidebar />

      <div className="container">

        <div className="row">
          <div className="section-title padd-15">
            <h2>About me</h2>
          </div>
        </div>
        <div className="row">
          <div className="about-content padd-15">
            <div className="row">
              <div className="about-text padd-15">
                <h3>I'm Xiaoqian He and <span>Software developer</span></h3>
                <p></p>
              </div>
            </div>
            <div className="row">
              <div className="personal-info padd-15" >
                <div className="row">
                  <div className="info-item padd-15">
                    <p>Brithday: <span> 01 sep 1995</span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>Age: <span> 27 </span></p>
                  </div>

                  <div className="info-item padd-15">
                    <p>Email: <span> sevenhxq@gmail.com</span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>Degree: <span>CS</span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>Phone: <span>+1 929-218-8983</span></p>
                  </div>
                  <div className="info-item padd-15">
                    <p>City: <span>New York</span></p>
                  </div>

                </div>
                <div className="row">
                  <div className="buttons padd-15">

                    <Link to="/contact" data-section-index="1" className='btn hire-me'>Contact Me</Link>
                  </div>
                </div>
              </div>
              <div className="skills padd-15">

                <div className="row">
                  <h2 className="text-center">Skills</h2>
                  <div className="skill-item padd-15">
                    <h5>JAVA</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "86%" }}></div>
                      <div className="skill-percent">86%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>C# .Net</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "88%" }} ></div>
                      <div className="skill-percent">88%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>Angular</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "80%" }} ></div>
                      <div className="skill-percent">80%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>JavaScript</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "85%" }}></div>
                      <div className="skill-percent">85%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>Node.js</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "80%" }} ></div>
                      <div className="skill-percent">80%</div>
                    </div>
                  </div>
                  <div className="skill-item padd-15">
                    <h5>HTML</h5>
                    <div className="progress">
                      <div className="progress-in" style={{ width: "86%" }} ></div>
                      <div className="skill-percent">86%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="education padd-15">
                <h3 className="title">Education</h3>
                <div className="row">
                  <div className="timeline-box padd-15">
                    <div className="timeline shadow-dark">

                      <div className="timeline-item">
                        <div className="circle-dot"></div>
                        <h3 className="timeline-date">
                          <i className="fa fa-calendar"></i> 2020 - 2023
                        </h3>
                        <h4 className="timeline-title">Bachelor in Computer Science</h4>
                      </div>

                      <div className="timeline-item">
                        <div className="circle-dot"></div>
                        <h3 className="timeline-date">
                          <i className="fa fa-calendar"></i> 2018 - 2020
                        </h3>
                        <h4 className="timeline-title">Associate of Occupational in Computer Programming and Inf.Tech</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
