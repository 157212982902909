import BlogList from "./BlogList";
import useFetch from "../useFetch";

const Home = () => {
  const url = 'https://blog-database-3-default-rtdb.firebaseio.com/blogs.json'; 
  const { data, isPending, error } = useFetch(url);

//   console.log(data);

  return (
    <div className="home">
      {error && <div>{error}</div>}
      {isPending && <div>Loading...</div>}

      {data && <BlogList blogs={data} title="Blogs List" />}
    </div>
  );
}

export default Home;
