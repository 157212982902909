import React, { useState, useEffect } from 'react';
import { IoColorPaletteSharp } from 'react-icons/io5';
import { MdDarkMode } from 'react-icons/md';
import { IoSunny } from 'react-icons/io5';
import './Theme/switch.css';
import './Theme/color-1.css';
import './Theme/color-2.css';
import './Theme/color-3.css';
import './Theme/color-4.css';
import './Theme/color-5.css';

const Switch = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);  const [isSwitcherOpen, setIsSwitcherOpen] = useState(false);
  
    const toggleSwitcher = () => {
      setIsSwitcherOpen((prev) => !prev);
    };
  
    const closeSwitcherOnScroll = () => {
      if (isSwitcherOpen) {
        setIsSwitcherOpen(true);
      }
    };
  
    const setActiveStyle = (color) => {
      localStorage.setItem('color', color);
      changeColor();
    };
  
    const changeColor = () => {
      const alternateStyles = document.querySelectorAll('.alternate-style');
      alternateStyles.forEach((style) => {
        if (localStorage.getItem('color') === style.getAttribute('title')) {
          style.removeAttribute('disabled');
        } else {
          style.setAttribute('disabled', 'true');
        }
      });
    };
  
    const toggleDarkMode = () => {
      setIsDarkMode((prevMode) => !prevMode);
      document.body.classList.toggle('dark');
    };
  
    useEffect(() => {
      if (document.body.classList.contains('dark')) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
  
      window.addEventListener('scroll', closeSwitcherOnScroll);
      return () => {
        window.removeEventListener('scroll', closeSwitcherOnScroll);
      };
    },  []);
  return (
    <div className={`style-switcher ${isSwitcherOpen ? 'open' : ''}`}>
      {/* <div className="style-switcher-toggler s-icon" onClick={toggleSwitcher}>
        <i>
          <IoColorPaletteSharp />
        </i>
      </div> */}
      <div className="day-night s-icon" onClick={toggleDarkMode}>
        {isDarkMode ? <IoSunny /> : <MdDarkMode />}
      </div>
      <h4>Theme Colors</h4>
      <div className="colors">
        <span className="color-1" onClick={() => setActiveStyle('color-1')}></span>
        <span className="color-2" onClick={() => setActiveStyle('color-2')}></span>
        <span className="color-3" onClick={() => setActiveStyle('color-3')}></span>
        <span className="color-4" onClick={() => setActiveStyle('color-4')}></span>
        <span className="color-5" onClick={() => setActiveStyle('color-5')}></span>
      </div>
    </div>
  );
};

export default Switch;
