import React from 'react';
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import FoodMenu from './FoodMenu';
const Food = () => {

    const { categoryId } = useParams();

    // Assuming you have an array of food categories with IDs
    const foodCategories = [
        { id: 1, name: '火锅', img:"https://www.gro-tech.nl/wp-content/uploads/2023/05/hotpot.jpg", link:"https://www.yelp.com/search?find_desc=Chinese+hotpot&find_loc=Flushing%2C+Queens%2C+NY"},
        { id: 2, name: '中餐', img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM-WXVMrbGtMmtrTg1ytjFcsk_CoqSenceqg&usqp=CAU", link:"https://www.yelp.com/search?find_desc=Chinese+Restaurant&find_loc=Flushing%2C+Queens%2C+NY"},
        { id: 3, name: '韩餐', img:"https://p2.itc.cn/q_70/images03/20221013/6859f41df0164a83ae9e2f20f6356e20.png", link:"https://www.yelp.com/search?find_desc=Korean+Food&find_loc=Flushing%2C+NY"},
        { id: 4, name: '泰餐', img:"https://we.superlife.ca/files/4ffce04d92a4d6cb21c1494cdfcd6dc129153.jpg",link:"https://www.yelp.com/search?find_desc=Thai+Food&find_loc=Flushing%2C+NY" },
        { id: 5, name: '烤肉', img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNpwZA4qXHunkOZmF9kB_fiGJypZ0g_nO_sw&usqp=CAU", link:"https://www.yelp.com/search?find_desc=bbq&find_loc=Flushing%2C+NY" },
        { id: 6, name: '烤鱼', img:"https://media-cdn.tripadvisor.com/media/photo-s/19/2a/d1/29/best-seller-chongking.jpg", link:"https://www.yelp.com/search?find_desc=%E7%83%A4%E9%B1%BC&find_loc=Flushing%2C+NY" },
    ];
    const selectedCategory = foodCategories.find(category => category.id === parseInt(categoryId));


    return (
        <div className="foodMenu row">
            {foodCategories.map(category => (
                <div key={category.id} className="cuisine col text-center">
                    {/* Pass the category array as a prop to FoodMenu */}
                    {/* <Link to={`/foodmenu/${category.id}`}> */}
                    <Link to={category.link}  style={{ textDecoration: 'none' }}>
                        <div className="circular--landscape">
                            <img src={category.img} alt="Card image cap" />
                        </div>
                        <h3 className="cuisineName" style={{ textDecoration: 'none' }}>{category.name}</h3>
                    </Link>
                </div>
            ))}
        </div>
    );
}

export default Food;
