// YelpHotpotList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const YelpHotpotList = () => {
    const [hotpotList, setHotpotList] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8000/yelp/NewYork');
  
          setHotpotList(response.data);
          console.log(response.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Top Hotpot Restaurants</h1>
      <ul>
        {hotpotList.map((restaurant, index) => (
          <li key={index}>
            <h2>{restaurant.name}</h2>
            <img src={restaurant.image} alt={`${restaurant.name} Thumbnail`} style={{ width: '100px', height: '100px' }} />
            <p>Open Time: {restaurant.openTime}</p>
            <p>Close Time: {restaurant.closeTime}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default YelpHotpotList;
