import React from 'react'
import Sidebar from './Sidebar'
export default function Contact() {
  return (
    <section class="contact section" id = "contact">
        <Sidebar />
    <div className="container">

        <div className="row">
            <div className="section-title padd-15">
                <h2>Contact Me</h2>
            </div>
        </div>
        <h3 className="contact-title padd-15">Feel Free To Contact Me</h3>
        <h4 className="contact-sub-title padd-15"> Currently residence in New York</h4>
        <div className="row">

            <div className="contact-info-item padd-15">
                <div className="icon"><i className="fa fa-phone"></i></div>
                <h4>Call me on</h4>
                <p> +19292188983</p>
            </div>
            <div className="contact-info-item padd-15">
                <div className="icon"><i className="fa fa-envelope"></i></div>
                <h4>Email</h4>
                <p>sevenhxq@gmail.com</p>
            </div>


            <div className="contact-info-item padd-15">
                <div className="icon"><i className="fa fab fa-linkedin"></i></div>
                <h4>linkedln</h4>
                <p>www.linkedin.com/in/xiaoqian-he-45190318a</p>
            </div>

        </div>

        <h3 className="contact-title">SEND ME EMAIL </h3>
        <h4 className="contact-sub-title">I'M VERY RESPOSIVE TO MESSAGES </h4>

       
    </div>
    </section>
  )
}
