import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './Login/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS

const Navbar = () => {
    const { isLoggedIn, login, logout } = useAuth();

    useEffect(() => {
        // Initialize Bootstrap dropdown functionality
        const dropdownToggle = document.querySelectorAll('.dropdown-toggle');
        dropdownToggle.forEach((toggle) => {
          toggle.addEventListener('click', function () {
            const dropdownMenu = this.nextElementSibling;
            dropdownMenu.classList.toggle('show');
          });
        });
    
        document.addEventListener('click', function (e) {
          const target = e.target;
          if (!target.matches('.dropdown-toggle')) {
            const dropdowns = document.querySelectorAll('.dropdown-menu');
            dropdowns.forEach((dropdown) => {
              if (dropdown.classList.contains('show')) {
                dropdown.classList.remove('show');
              }
            });
          }
        });
      
    }, []);

    return (

        <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">XIAOQIANHE</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/blogs">Blogs</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/convert">Convert</Link>
              </li>
              <li className="nav-item">
              {!isLoggedIn && (<Link className="nav-link" to='/login'>Login</Link>)}
              </li>
              {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="game" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Games
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><Link className="dropdown-item" to="/games/tetris">Tetris</Link></li>
                  <li><Link className="dropdown-item" to="/games/numbergame">Number Game</Link></li>
                </ul>
              </li> */}
                <li className="nav-item">
                <Link className="nav-link" to="/games/numbergame">Num Games</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/random">Random</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
}

export default Navbar;