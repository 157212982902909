import React from 'react'
import { useState } from 'react'
import convert from 'convert-units';
import './convert.css'
const ConversionComponent = ({ fromUnit, toUnit }) => {
  const [inputValue, setInputValue] = useState(0);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };
  const firstLetterUpper = (word) => {
    if (word.length === 0) return word;
    return word.charAt(0).toUpperCase() + word.slice(1);
  }



  const convertUnits = () => {
    const result = convert(inputValue).from(fromUnit).to(toUnit);
    return result.toFixed(2); // Adjust decimal places as needed
  };
  return (

    <div className='length unitChoose row'>
      <label className='col-1'>{fromUnit}</label>
      <input className='col-4 ' type="number" value={inputValue} onChange={handleInputChange} />

      <label className="col-2 equal">=</label>

      <label className='col-1'>{toUnit}</label>
      <input className='col-4 ' type="text" value={convertUnits()} readOnly />
    </div>
  );
};
export default ConversionComponent;

