import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { IoIosRefresh } from "react-icons/io";
export default function BlogList({ blogs, title, handleDelete }) {
  // Convert the object to an array
  const blogArray = Object.values(blogs);
  const [inputText, setInputText] = useState('');
  const [placeHolder, setPlaceHolder] = useState("What are you looking for? ")
  const [searchedBlogs, setSearchedBlogs] = useState(null); 
  const inputHandler = (e) => {

    // Access the value from the input field
    const inputElement = document.querySelector('.searchTerm');
    if (inputElement) {
      const text = inputElement.value.toLowerCase();
      setInputText(text);
      // setSearch(true); 
      setPlaceHolder("What are you looking for?")
      console.log("Get value successfully", inputText); 
      
    } else {
      console.error("Input element with class 'searchTerm' not found.");
    }

    setPlaceHolder("New placeholder text here");
  };
  const searchHandler = () => {
    const filteredBlogs = Object.values(blogs).filter(blog =>
      blog.title.toLowerCase().includes(inputText) ||
      blog.author.toLowerCase().includes(inputText) ||
      blog.date.toLowerCase().includes(inputText)
    );
    setSearchedBlogs(filteredBlogs);
  };
  const resetSearch = () => {
    setSearchedBlogs(null);
    setInputText('');
  };
  const blogsToDisplay = searchedBlogs || blogs || [];
  
  return (
    <div className="grid text-center">

<div className="wrap">
      <div className="search">
        {/* Bind the input value to the state variable */}
        <input type="text" className="searchTerm" placeholder={placeHolder}   onChange={inputHandler}/>
        {/* Pass event to inputHandler function on button click */}
        <button type="submit" className="searchButton" onClick={searchHandler}>
          <i className="bi bi-search"></i>
        </button>
        <button type="button" className="resetButton mx-5" onClick={resetSearch}>
        <IoIosRefresh />
        
          </button>
      </div>
    </div>
      <div className="blogs-container">

        { Object.entries(blogsToDisplay).map(([id, blog]) => (
          <div className="blog-preview" key={id}>
            <Link to={`/blogs/${id}`}>
              <div className="card mb-3">
                <div className="card-img-container">
                  <img className="card-img-top" src={blog.image} alt="image Preview" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{blog.title}</h5>
                  <p className="card-text">Author: {blog.author}</p>
                  <p className="card-text"><small className="text-muted">Date: {blog.date}</small></p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
