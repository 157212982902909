// firebase-config.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// import { firebaseConfig } from './firebaseConfig';
const firebaseConfig = {
    apiKey: "AIzaSyDeS5UADexLO21glPIoj5dD8mFLmM4F8zc",
    authDomain: "blog-database-3.firebaseapp.com",
    databaseURL: "https://blog-database-3-default-rtdb.firebaseio.com",
    projectId: "blog-database-3",
    storageBucket: "blog-database-3.appspot.com",
    messagingSenderId: "138526460738",
    appId: "1:138526460738:web:dd824d10f09bb1df5a96db",
    measurementId: "G-7QZ0DH624S"
  };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  
  export { storage };
