// UploadImage.jsx
import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useState } from 'react';
import { getApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage as firebaseStorage } from '../Firebase/firebase-config';

const UploadImage = ({ onImageUpload }) => {
    const app = getApp();
    console.log('Storage Object:', firebaseStorage);

    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const handleChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleUpload = async () => {
        if (file) {
            try {
                const storageRef = ref(getStorage(app), `images/${file.name}`);
                const snapshot = await uploadBytes(storageRef, file);

                const downloadURL = await getDownloadURL(snapshot.ref);
                setImageUrl(downloadURL);
                onImageUpload(downloadURL);
                console.log('File uploaded successfully:', downloadURL);
                setFile(null);
            } catch (error) {
                console.error('Error uploading file:', error.message);
            }
        }
    };

    return (
        <div className="imageUpload">
        <div className="row">
          <div className="col-6 d-flex align-items-center">
            <input type="file" onChange={handleChange} />
            {imageUrl && <i className="mx-2 text-success icon bi bi-check2"></i>}
          </div>
          <div className="col-3 d-flex align-items-center">
            <button  onClick={handleUpload}>Upload</button>
          </div>
          <div className="col-3">
            {imageUrl && (
              <div className="d-flex align-items-center">
                <img className="img-thumbnail" src={imageUrl} alt="Uploaded" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default UploadImage;
