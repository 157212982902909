import React from 'react'
import './convert.css'
import { useState } from 'react'
import ConversionComponent from './convertUnit';
import { json } from 'react-router-dom';
import { set } from 'firebase/database';
export default function Convert() {

    const validUnitPairs = [
        { from: 'cm', to: 'in' },
        { from: 'in', to: 'cm' },
        { from: 'm', to: 'ft' },
        { from: 'ft', to: 'm' },
        { from: 'km', to: 'mi' },
        { from: 'mi', to: 'km' },
        { from: 'kg', to: 'lb' },
        { from: 'lb', to: 'kg' },
        { from: 'g', to: 'oz' },
        { from: 'oz', to: 'g' },
        { from: 'm', to: 'yd' },
        { from: 'yd', to: 'm' },
        { from: 'g', to: 'lb' },
        { from: 'lb', to: 'g' },
        { from: 'mm', to: 'cm' },
        { from: 'cm', to: 'mm' },
        { from: 'km', to: 'm' },
        { from: 'm', to: 'km' },
        { from: 'ml', to: 'fl oz' },
        { from: 'fl oz', to: 'ml' },
    ];
    const [pairs, setPairs] = new useState(validUnitPairs[0]);
    const handlePairsChoose = (e) => {
        const selectedPair = JSON.parse(e.target.value);
        setPairs(selectedPair);
    }
    const [fahrenheit, setFahrenheit] = useState(0);
    const [celsius, setCelsius] = useState(0);
  
    const fToC = (e) => {
      const targetF = e.target.value;
      setFahrenheit(targetF); 
      const convertToC = (targetF - 32) / 9 * 5;
      setCelsius(convertToC.toFixed(2));
    };
  
    const cToF = (e) => {
      const targetC = e.target.value;
      setCelsius(targetC);
      const convertToF = targetC * 9 / 5 + 32;
      setFahrenheit(convertToF.toFixed(2));
    };
    return (
        <div className="convert">
            <div className="title">
                <h1 className='text-center pb-5'>
                    This is the convert page
                </h1>
            </div>
            <div className='units'>
            <div className='length tempture unitChoose row'>
                    <label className='col-1'>ºF</label>
                    <input className='col-4' type="text" value={fahrenheit} onChange={fToC} />

                    <label className="col-2 equal">=</label>

                    <label className='col-1'>ºC</label>
                    <input className='col-4' type="text" value={celsius} onChange={cToF} />
                </div>
                <ConversionComponent fromUnit="in" toUnit="cm" />
                <ConversionComponent fromUnit="lb" toUnit="kg" />
                <ConversionComponent fromUnit="cm" toUnit="in" />
               
            </div>
            <div className="chooseUnit">
                <hr></hr>
                <h2 className='text-center pt-5 pb-3'>Free to choose the unit you want</h2>
                <div className="chooseForm">


                    <div className="selectForm row">
                        <label htmlFor="pairs" className='col-4'>Choose unit: </label>
                        <select className='w-50 text-center col-8' id="pairs" value={JSON.stringify(pairs)} onChange={handlePairsChoose}>
                            {validUnitPairs.map((pair) => (
                                <option key={JSON.stringify(pair)} value={JSON.stringify(pair)}>
                                    {pair.from} to {pair.to}
                                </option>
                            ))}
                        </select>
                    </div>
                    <ConversionComponent fromUnit={pairs.from} toUnit={pairs.to} />
                </div>
            </div>
        </div>
    )
}
