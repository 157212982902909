import React from 'react'
import './coverPage.css'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom';
export default function Profile() {
  return (
    <section className="portfolio section " id = "portfolio">
     <Sidebar />
    <div className="container">
        <div className="row">
            <div className="section-title padd-15">
                <h2>Portfolio</h2>
            </div>
        </div>
        <div className="row">
            <div className="portfolio-heading padd-15">
                <h2>My Latest Projects: </h2>
            </div>
        </div>
        <div className="row">

            <div className="portfolio-item padd-15">
                <div className="portfolio-item-inner shadow-dark">
                    <div className="portfolio-img">
                        <a target = "_blank" href="https://shawnhxq.github.io/XiaoqianHe.github.io/">
                        <img src ="https://i.imgur.com/cpI6scI.png" alt=""/></a>
                    </div>
                </div>
            </div>
            <div className="portfolio-item padd-15">
                <div className="portfolio-item-inner shadow-dark">
                    <div className="portfolio-img">
                        <Link to={'/showsDetail'}>  <img src ="https://i.imgur.com/8uW9w4a.png" alt=""/></Link>
                      
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
  )
}
