import React from 'react';
import useFetch from '../useFetch';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Login/AuthContext';
import Comments from '../Comment/Comments';

const BlogDetails = () => {
  const { isLoggedIn, login, logout } = useAuth();
  const { id } = useParams();
  const url = `https://blog-database-3-default-rtdb.firebaseio.com/blogs/${id}.json`;
  const { data: blog, isPending, error } = useFetch(url);
  const navigate = useNavigate();


  const deleteBlog = () => {
    fetch(url, {
      method: 'DELETE'
    })
      .then(() => {
        console.log("blog delete successfully!");
        navigate('/blogs')
      })
  }
  return (
    <div className='blog-details'>

      {isPending && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {blog && (
        <div className='details'>
          <img className="detailImage" src={blog.image} alt="" />
          {/* <h2>Blog Id: {blog.id}</h2> */}
          <h2 className="py-3">Title: {blog.title}</h2>
          <div className="title row">
          <p className="col-6">Author:{blog.author}</p>
          <p className="col-6">Date: {blog.date}</p>
          </div>

          <div className='detialBody'>
            {blog.body}
            <hr></hr>
            <Comments blogId={id} />
          </div>

          {isLoggedIn && (<button className="delete" onClick={deleteBlog}>Delete</button>
          )}
        
        </div>
      )}


    </div>
  );
};

export default BlogDetails;
