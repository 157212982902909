import React, { useEffect } from 'react';
import {Link} from 'react-router-dom'
import Typed from 'typed.js';
import Sidebar from './Sidebar';
import Switch from './Switch';
import './coverPage.css';

export default function ConverPage() {
  useEffect(() => {
    const options = {
      strings: ["", "Full-Stack Developer", "Web Designer", "Software Developer"],
      typeSpeed: 100,
      backSpeed: 60,
      loop: true,
    };

    const typed = new Typed(".typing", options);

    // Clean up the Typed instance on component unmount
    return () => {
      typed.destroy();
    };
  }, []); // Run the effect only once when the component mounts

  return (
    <div className="main-container">
      <Sidebar />
      <div className="main-content">
        <section className="home active section" id="home">
          <div className="container">
            <div className="row">
              <div className="home-info padd-15">
                <h3 className="hello">Hello, my name is <span className="name">Xiaoqian He</span></h3>
                <h3 className="my-profession">I'm a <span className="typing"></span></h3>

                <Link to="/about" className="btn">View Details</Link>
              </div>
              <div className="home-img padd-15">
                <img src="https://i.imgur.com/U24TmnM.jpg" alt="" />
              </div>
            </div>
          </div>
          <Switch />
        </section>
      </div>
    </div>
  );
}
