import React, { useState, useEffect } from 'react';
import './game.css'
export default function NumberGame() {
  const [selectItem, setSelectItem] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleShow = () => {
    setIsVisible(true);
  };

  const handleHide = () => {
    setIsVisible(false);
  };


  const furniture = [
    { englishName: 'Chair', chineseName: '椅子' },
    { englishName: 'Table', chineseName: '桌子' },
    { englishName: 'Sofa', chineseName: '沙发' },
    { englishName: 'Bed', chineseName: '床' },
    { englishName: 'Desk', chineseName: '书桌' },
    { englishName: 'Cabinet', chineseName: '柜子' },
    { englishName: 'Shelf', chineseName: '书架' },
    { englishName: 'Dresser', chineseName: '梳妆台' },
    { englishName: 'Wardrobe', chineseName: '衣柜' },
    { englishName: 'Bench', chineseName: '长椅' },
    { englishName: 'Stool', chineseName: '凳子' },
    { englishName: 'Ottoman', chineseName: '脚凳' },
    { englishName: 'Armchair', chineseName: '扶手椅' },
    { englishName: 'Chaise Lounge', chineseName: '躺椅' },
    { englishName: 'Futon', chineseName: '折叠床' },
    { englishName: 'Coffee Table', chineseName: '咖啡桌' },
    { englishName: 'Side Table', chineseName: '边桌' },
    { englishName: 'Console Table', chineseName: '台子' },
    { englishName: 'Nightstand', chineseName: '床头柜' },
    { englishName: 'Dining Table', chineseName: '餐桌' },
    { englishName: 'Dining Chair', chineseName: '餐椅' },
    { englishName: 'Bar Stool', chineseName: '吧凳' },
    { englishName: 'Counter', chineseName: '柜台' },
    { englishName: 'Bookcase', chineseName: '书柜' },
    { englishName: 'TV Stand', chineseName: '电视柜' },
    { englishName: 'Chest', chineseName: '箱子' },
    { englishName: 'Trunk', chineseName: '行李箱' },
    { englishName: 'Hutch', chineseName: '厨柜' },
    { englishName: 'Drawer', chineseName: '抽屉' },
    { englishName: 'Wine Rack', chineseName: '酒架' },
    { englishName: 'Coat Rack', chineseName: '衣帽架' },
    { englishName: 'Mirror', chineseName: '镜子' },
    { englishName: 'Room Divider', chineseName: '隔断' },
    { englishName: 'Bunk Bed', chineseName: '双层床' },
    { englishName: 'Folding Chair', chineseName: '折叠椅' },
    { englishName: 'Bean Bag', chineseName: '豆袋椅' },
    { englishName: 'Rocking Chair', chineseName: '摇椅' },
    { englishName: 'Recliner', chineseName: '躺椅' },
    { englishName: 'Pouf', chineseName: '脚凳' },
    { englishName: 'Footstool', chineseName: '脚凳' },
    { englishName: 'Chaise', chineseName: '躺椅' },
    { englishName: 'Sleeper Sofa', chineseName: '沙发床' },
    { englishName: 'Sectional Sofa', chineseName: '组合沙发' },
    { englishName: 'Settee', chineseName: '小沙发' },
    { englishName: 'Loveseat', chineseName: '双人沙发' },
    { englishName: 'Daybed', chineseName: '白天床' },
    { englishName: 'Canopy Bed', chineseName: '帷幔床' },
    { englishName: 'Murphy Bed', chineseName: '墙床' },
    { englishName: 'Sleigh Bed', chineseName: '雪橇床' },
    { englishName: 'Platform Bed', chineseName: '平台床' },
    { englishName: 'Poster Bed', chineseName: '帷幔床' },
    { englishName: 'Crib', chineseName: '婴儿床' },
    { englishName: 'Changing Table', chineseName: '换尿布台' },
    { englishName: 'High Chair', chineseName: '高椅' },
    { englishName: 'Rocking Horse', chineseName: '摇摆马' },
    { englishName: 'Playpen', chineseName: '围栏' },
    { englishName: 'Cradle', chineseName: '摇篮' },
    { englishName: 'Baby Swing', chineseName: '摇篮椅' },
    { englishName: 'Bassinet', chineseName: '摇篮床' },
    { englishName: 'Walker', chineseName: '行走器' },
    { englishName: 'Jumper', chineseName: '跳跃器' },
    { englishName: 'Baby Bouncer', chineseName: '婴儿摇椅' },
    { englishName: 'Highchair', chineseName: '高椅' },
    { englishName: 'Glider', chineseName: '滑翔椅' },
    { englishName: 'Wing Chair', chineseName: '扶手椅' },
    { englishName: 'Chaise Longue', chineseName: '躺椅' },
    { englishName: 'Bergere', chineseName: '贵妃椅' },
    { englishName: 'Corner Shelf', chineseName: '角架' },
    { englishName: 'Etagere', chineseName: '陈列架' },
    { englishName: 'Bamboo Chair', chineseName: '竹椅' },
    { englishName: 'Rattan Chair', chineseName: '藤椅' },
    { englishName: 'Bar Chair', chineseName: '吧椅' },
    { englishName: 'Bar Table', chineseName: '吧台' },
    { englishName: 'Stool Chair', chineseName: '凳椅' },
    { englishName: 'Stool Bench', chineseName: '凳子长椅' },
    { englishName: 'Wooden Chair', chineseName: '木椅' },
    { englishName: 'Wooden Table', chineseName: '木桌' },
    { englishName: 'Wooden Bench', chineseName: '木长椅' },
    { englishName: 'Wooden Stool', chineseName: '木凳子' },
    { englishName: 'Wooden Shelf', chineseName: '木书架' },
    { englishName: 'Wooden Cabinet', chineseName: '木柜子' },
    { englishName: 'Wooden Wardrobe', chineseName: '木衣柜' },
    { englishName: 'Wooden Bed', chineseName: '木床' },
    { englishName: 'Wooden Desk', chineseName: '木书桌' },
    { englishName: 'Wooden Drawer', chineseName: '木抽屉' },
    { englishName: 'Wooden Chest', chineseName: '木箱子' },
    { englishName: 'Wooden Trunk', chineseName: '木行李箱' },
    { englishName: 'Wooden TV Stand', chineseName: '木电视柜' },
    { englishName: 'Wooden Dresser', chineseName: '木梳妆台' },
    { englishName: 'Wooden Bookcase', chineseName: '木书柜' },
    { englishName: 'Wooden Nightstand', chineseName: '木床头柜' },
    { englishName: 'Wooden Coffee Table', chineseName: '木咖啡桌' },
    { englishName: 'Wooden Dining Table', chineseName: '木餐桌' },
    { englishName: 'Wooden Dining Chair', chineseName: '木餐椅' },
    { englishName: 'Wooden Bar Stool', chineseName: '木吧凳' },
    { englishName: 'Wooden Rocking Chair', chineseName: '木摇椅' },
    { englishName: 'Wooden Recliner', chineseName: '木躺椅' },
    { englishName: 'Wooden Chaise Lounge', chineseName: '木躺椅' },
  ];

  const fruits = [
    { chineseName: '苹果', englishName: 'Apple' },
    { chineseName: '香蕉', englishName: 'Banana' },
    { chineseName: '橙子', englishName: 'Orange' },
    { chineseName: '葡萄', englishName: 'Grape' },
    { chineseName: '草莓', englishName: 'Strawberry' },
    { chineseName: '梨', englishName: 'Pear' },
    { chineseName: '桃子', englishName: 'Peach' },
    { chineseName: '樱桃', englishName: 'Cherry' },
    { chineseName: '西瓜', englishName: 'Watermelon' },
    { chineseName: '哈密瓜', englishName: 'Cantaloupe' },
    { chineseName: '柠檬', englishName: 'Lemon' },
    { chineseName: '菠萝', englishName: 'Pineapple' },
    { chineseName: '火龙果', englishName: 'Dragon Fruit' },
    { chineseName: '木瓜', englishName: 'Papaya' },
    { chineseName: '芒果', englishName: 'Mango' },
    { chineseName: '蓝莓', englishName: 'Blueberry' },
    { chineseName: '荔枝', englishName: 'Lychee' },
    { chineseName: '榴莲', englishName: 'Durian' },
    { chineseName: '柿子', englishName: 'Persimmon' },
    { chineseName: '杨桃', englishName: 'Starfruit' },
    { chineseName: '石榴', englishName: 'Pomegranate' },
    { chineseName: '杏子', englishName: 'Apricot' },
    { chineseName: '柑橘', englishName: 'Citrus' },
    { chineseName: '柚子', englishName: 'Pomelo' },
    { chineseName: '百香果', englishName: 'Passion Fruit' },
    { chineseName: '无花果', englishName: 'Fig' },
    { chineseName: '龙眼', englishName: 'Longan' },
    { chineseName: '荸荠', englishName: 'Water Chestnut' },
    { chineseName: '橄榄', englishName: 'Olive' },
    { chineseName: '枇杷', englishName: 'Loquat' },
    { chineseName: '椰子', englishName: 'Coconut' },
    { chineseName: '山竹', englishName: 'Mangosteen' },
    { chineseName: '榴莲', englishName: 'Durian' },
    { chineseName: '牛油果', englishName: 'Avocado' },
    { chineseName: '车厘子', englishName: 'Cherry' },
    { chineseName: '李子', englishName: 'Plum' },
    { chineseName: '枣子', englishName: 'Date' },
    { chineseName: '黑加仑', englishName: 'Black Currant' },
    { chineseName: '杨梅', englishName: 'Bayberry' },
    { chineseName: '山楂', englishName: 'Hawthorn' },
    { chineseName: '莲雾', englishName: 'Wax Apple' },
    { chineseName: '番石榴', englishName: 'Guava' },
    { chineseName: '桑葚', englishName: 'Mulberry' },
    { chineseName: '草莓', englishName: 'Strawberry' },
    { chineseName: '黑莓', englishName: 'Blackberry' },
    { chineseName: '覆盆子', englishName: 'Raspberry' },
    { chineseName: '猕猴桃', englishName: 'Kiwi' },
    { chineseName: '火龙果', englishName: 'Pitaya' },
    { chineseName: '沙棘', englishName: 'Sea Buckthorn' },
    { chineseName: '蔓越莓', englishName: 'Cranberry' },
    { chineseName: '人参果', englishName: 'Ginseng Fruit' }
  ];
  const famousPeople = [
    { englishName: 'Albert Einstein', field: 'Physics', chineseName: '阿尔伯特·爱因斯坦' },
    { englishName: 'Leonardo da Vinci', field: 'Art', chineseName: '达·芬奇' },
    { englishName: 'William Shakespeare', field: 'Literature', chineseName: '威廉·莎士比亚' },
    { englishName: 'Genghis Khan', field: 'History', chineseName: '成吉思汗' },
    { englishName: 'Galileo Galilei', field: 'Astronomy', chineseName: '伽利略' },
    { englishName: 'Isaac Newton', field: 'Physics', chineseName: '艾萨克·牛顿' },
    { englishName: 'Wolfgang Amadeus Mozart', field: 'Music', chineseName: '莫扎特' },
    { englishName: 'Vincent van Gogh', field: 'Art', chineseName: '梵高' },
    { englishName: 'Pablo Picasso', field: 'Art', chineseName: '巴勃罗·毕加索' },
    { englishName: 'Charles Darwin', field: 'Biology', chineseName: '查尔斯·达尔文' },
    { englishName: 'Mao Zedong', field: 'Politics', chineseName: '毛泽东' },
    { englishName: 'Vincent van Gogh', field: 'Art', chineseName: '梵高' },
    { englishName: 'Confucius', field: 'Philosophy', chineseName: '孔子' },
    { englishName: 'Sun Tzu', field: 'Military', chineseName: '孙子' },
    { englishName: 'Laozi', field: 'Philosophy', chineseName: '老子' },
    { englishName: 'Mencius', field: 'Philosophy', chineseName: '孟子' },
    { englishName: 'Zhuangzi', field: 'Philosophy', chineseName: '庄子' },
    { englishName: 'Li Bai', field: 'Poetry', chineseName: '李白' },
    { englishName: 'Du Fu', field: 'Poetry', chineseName: '杜甫' },
    { englishName: 'Cao Cao', field: 'History', chineseName: '曹操' },
    { englishName: 'Liu Bei', field: 'History', chineseName: '刘备' },
    { englishName: 'Zhuge Liang', field: 'History', chineseName: '诸葛亮' },
    { englishName: 'Emperor Qin Shi Huang', field: 'History', chineseName: '秦始皇' },
    { englishName: 'Jackie Chan', field: 'Film', chineseName: '成龙' },
    { englishName: 'Bruce Lee', field: 'Film', chineseName: '李小龙' },
    { englishName: 'Zhang Ziyi', field: 'Film', chineseName: '章子怡' },
    { englishName: 'Jet Li', field: 'Film', chineseName: '李连杰' },
    { englishName: 'Fan Bingbing', field: 'Film', chineseName: '范冰冰' },
    { englishName: 'Lang Lang', field: 'Music', chineseName: '郎朗' },
    { englishName: 'Jay Chou', field: 'Music', chineseName: '周杰伦' },
    { englishName: 'Liu Xiang', field: 'Sports', chineseName: '刘翔' },
    { englishName: 'Yao Ming', field: 'Sports', chineseName: '姚明' },
    { englishName: 'Li Na', field: 'Sports', chineseName: '李娜' },
    { englishName: 'Deng Yaping', field: 'Sports', chineseName: '邓亚萍' },
    { englishName: 'Yuan Longping', field: 'Science', chineseName: '袁隆平' },
  ]
  const number = () => {
    const randomNum = Math.floor(Math.random() * 1000) + 1;
    setSelectItem(randomNum);

  }
  const selectFruit = () => {
    const randomNum = Math.floor(Math.random() * fruits.length);
    setSelectItem(fruits[randomNum]);
  };
  const selectCharacter = () => {
    const randomNum = Math.floor(Math.random() * famousPeople.length);
    setSelectItem(famousPeople[randomNum]);
  }
  const selectFurniture = () => {
    const randomNum = Math.floor(Math.random() * furniture.length);
    setSelectItem(furniture[randomNum]);
  }



  ////Time clock 
  const initialTime = 5 * 60; // 5 minutes in seconds
  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  useEffect(() => {
    let timer;
    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning, time]);

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
  };
  const handleReset = () => {
    setTime(initialTime);
    setIsRunning(false);
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="number_container">
      <h2 className="menu p-5">
        choose the type of the items:
      </h2>
      <div className="row">
        <div className="btns left col">
          <button className='item btn btn-1' onClick={number}>Numbers <span className='mx-5 float-end'>5 points</span></button>
          <button className='item btn btn-2' onClick={selectFruit}>Fruits <span className='mx-5 float-end'>5 points</span></button>
          <button className='item btn btn-3' onClick={selectCharacter}>Character <span className='mx-5 float-end'>10 points</span></button>
          <button className='item btn btn-4' onClick={selectFurniture}>Furniture <span className='mx-5 float-end'>10 points</span></button>
        </div>
        <div className="right col">
          <p className={`result  ${isVisible ? 'visible' : 'hidden'}`}>
            {selectItem && typeof selectItem === 'object' ? (
              <span>
                {selectItem.chineseName} <br /> ({selectItem.englishName})
              </span>
            ) : (
              selectItem
            )}
          </p>
        </div>
      </div>
      <div className="row">
      <button className='btn col mx-5 my-5 btn-success' onClick={handleShow}>Show</button>
        <button className='btn col mx-5 my-5 btn-danger' onClick={handleHide}>Hide</button>
      </div>
      <div className="row">
      <h2 className='col'>Countdown Timer</h2>
      <h1  className='col timer'>{formatTime(time)}</h1>
      <div className="row">

      </div>
      {!isRunning ? (
        <button className='btn col btn-primary mx-5 my-3 ' onClick={handleStart}>Start</button>
      ) : (
        <button className='btn col btn-primary mx-5 my-3 ' onClick={handleStop}>Stop</button>
      )}
       <button  className='btn col btn-light mx-5 my-3 ' onClick={handleReset}>Reset</button>
    </div>
    </div>
  )
}
