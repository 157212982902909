import React from 'react';

const Game = () => {
    return (
        <div>
            This is the game page 
        </div>
    );
}

export default Game;
