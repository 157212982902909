import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdInformationCircle } from 'react-icons/io';
import { RiProfileFill } from 'react-icons/ri';
import { IoHome } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';
import { GiFox } from 'react-icons/gi';

export default function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`aside ${isSidebarOpen ? 'open' : ''}`}>
      <div className="logo">
        <Link to="#">
          <span>X</span>H
        </Link>
      </div>
      <div className="nav-toggler" onClick={toggleSidebar}>
        <span></span>
      </div>
      <ul className="nav">
        <li>
          <Link to="/" className="item active">
            <i>
              <IoHome />
            </i>{' '}
            Home
          </Link>
        </li>
        <li>
          <Link to="/blogs" className="item">
            <i>
              <GiFox />
            </i>
            Blog
          </Link>
        </li>
        <li>
          <Link to="/about" className="item">
            <i>
              <IoMdInformationCircle />
            </i>
            About
          </Link>
        </li>
        <li>
          <Link to="/profile" className="item">
            <i>
              <RiProfileFill />
            </i>
            Portfolio
          </Link>
        </li>
        <li>
          <Link to="/contact" className="item">
            <i>
              <MdEmail />
            </i>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
}
