import { useState, useEffect } from "react";

const useFetch = (url,options = {}) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setErrorMessage] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const abortCont = new AbortController();

    fetch(url, {
      method: options.method || "GET",
      headers: options.headers || {},
      body: options.body ? JSON.stringify(options.body) : null,
      signal: abortCont.signal,
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(`Could not fetch the data, status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (isMounted) {
          setData(data);
          setIsPending(false);
          setErrorMessage(null);
        }
      })
      .catch((e) => {
        if (e.name === "AbortError" && isMounted) {
          console.log("Fetch aborted");
        } else if (isMounted) {
          setIsPending(false);
          setErrorMessage(e.message);
        }
      });

    return () => {
      abortCont.abort();
      isMounted = false;
    };
  },  [url, options.method, options.headers, options.body]);


  return { data, isPending, error };
};

export default useFetch;
