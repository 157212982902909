import React from 'react';

const Shows = () => {
    return (
        <div className='conent'>
            <h1>Furinturee-commerce website</h1>
        </div>
    );
}

export default Shows;
