import React, { useState, useEffect } from 'react';
import useFetch from '../useFetch';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import "./login.css"
export default function Login() {

  const { isLoggedIn, login, logout } = useAuth();

  const [user, setUser] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState(false);

  const navigator = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    if (username === user.username && password === user.password) {
      setLoginStatus(true);
      login();
      console.log("account match!")
      alert("Your are able to modify the blogs")
      navigator('/blogs')
    } else {
      alert("Incorrect input");
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://blog-database-3-default-rtdb.firebaseio.com/user.json");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonData = await response.json();
        setUser(jsonData[0]);//Index 0 for the user information 
        setIsPending(false);
      } catch (error) {
        setError(error.message);
        setIsPending(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <div className="container">
  <div className="login d-flex flex-column align-items-center">
    <form onSubmit={handleLogin} className="loginForm">
      <h2 className="text-light text-center">Login</h2>
      <div className="form-group">
        <label className="w-100 form-label">Username:</label>
        <input
            className="form-control"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
      </div>
      <div className="form-group">
        <label className="w-100 form-label"> Password: </label>
        <input
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
      </div>
      <div className="mt-auto button-container"> {/* Use mt-auto to push the button to the bottom */}
        <button className="btn btn-primary login-btn  m-2" type="submit">
          Login
        </button>
        <br></br>
        <Link to="/blogs">Cancal</Link>

      </div>
    </form>
    {loginStatus && <p>{loginStatus}</p>}
  </div>
</div>

  );
}
