//App.js

import React, { useState } from 'react';
import './App.css';
import Navbar from './Navbar';
import Home from './Blog/Home';
import Create from './Blog/Create.jsx';
import BlogDetails from './Blog/BlogDetails';
import Convert from './Convert//Convert';
import NotFound from './NotFound';
import Foot from './footer/Foot';
import Login from './Login/Login';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import Logout from './Login/Logout';
import ConverPage from './Cover/CoverPage';
import About from './Cover/About';
import Profile from './Cover/Profile';
import Contact from './Cover/Contact';
import Random from './Random/Random';
import FoodMenu from './Random/FoodMenu';
import Food from './Random/food';
import YelpHotpotList from './Random/YelpHotpotList';
import UploadImage from './Blog/uploadImage';
import Tetris from './Game/Tetris/Tetris';
import Game from './Game/game';
import Shows from './Cover/ProfileDetail/Shows';
import NumberGame from './Game/NumberGame';
function App() {
  const foodCategories = [
    { id: 1, name: '火锅', img:"https://www.gro-tech.nl/wp-content/uploads/2023/05/hotpot.jpg"},
    { id: 2, name: '中餐', img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM-WXVMrbGtMmtrTg1ytjFcsk_CoqSenceqg&usqp=CAU"},
    { id: 3, name: '韩餐', img:"https://p2.itc.cn/q_70/images03/20221013/6859f41df0164a83ae9e2f20f6356e20.png"},
    { id: 4, name: '泰餐', img:"https://we.superlife.ca/files/4ffce04d92a4d6cb21c1494cdfcd6dc129153.jpg" },
    { id: 5, name: '烤肉烤鱼', img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNpwZA4qXHunkOZmF9kB_fiGJypZ0g_nO_sw&usqp=CAU" },
];


  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className='content'>
          <Routes>
            <Route path='/' element={<ConverPage />}></Route>
            <Route path='/blogs' element={<Home />}></Route>
            <Route path='/create' element={<Create />}></Route>
            <Route path='/convert' element={<Convert />}></Route>
            <Route path='/blogs/:id' element={<BlogDetails />}> </Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/logout' element={<Logout />}></Route>
            <Route path='*' element={<NotFound />}> </Route>
            {/* The * means not match any route */}
            <Route path='/about' element={<About />}></Route>
            <Route path='/profile' element={<Profile />}></Route>
            <Route path='/contact' element={<Contact />}></Route>
            <Route path='/uploadimage' element={<UploadImage />}></Route>
            <Route path='/random' element={<Random/>}></Route>
            <Route path="/food" element={<Food foodCategories={foodCategories} />} />

            <Route path="/foodmenu/:categoryId" element={<FoodMenu foodCategories={foodCategories} />} />
            <Route path="/Yelplist" element={<YelpHotpotList/>}></Route>
            <Route path='/games' element={<Game />}></Route>
  
            <Route path='/games/tetris' element={<Tetris/>}></Route>
            <Route path='/games/numbergame' element={<NumberGame/>}></Route>
            <Route path='/showsDetail' element ={<Shows /> }></Route>
            {/* <Route path='/random' element={<Random />}></Route> */}

          </Routes>
        </div>
        <Foot />
      </div>
    </Router>
  );
}

export default App;
